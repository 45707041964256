import {useEffect, useState} from 'react'

const FallbackSpinner = () => {
  const [progress, setProgress] = useState(0)
  useEffect(() => {
    let current_progress = 0
    let step = 0.5
    const fakeProgress = setInterval(function () {
      current_progress += step
      let progress = Math.round(
        (Math.atan(current_progress) / (Math.PI / 2)) * 100,
      )

      if (progress >= 100) {
        clearInterval(fakeProgress)
      } else if (progress >= 70) {
        step = 0.2
      }

      setProgress(progress)
    }, 100)
    return () => clearTimeout(fakeProgress)
  }, [])

  return (
    <div className="fallback-spinner app-loader">
      <img
        width={300}
        height={200}
        className="fallback-logo"
        src="https://b-alpha.vn/templates/protostar/images/logo-vertical.svg"
        alt="logo"
      />
      <div className="loading position-relative">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
        <div className="progress-num">{progress}%</div>
      </div>
    </div>
  )
}

export default FallbackSpinner
