import {QueryClient} from 'react-query'
import toast from 'react-hot-toast'
import ErrorConfig from 'configs/errors'

export function queryErrorGetHandler(error) {
  if (error.response) {
    const errorData = error.response.data
    if (errorData && errorData.error_code) {
      toast.error(errorData.error_code)
    }
  }
}

export const renderMessageError = (
  response,
  callbackSuccess,
  callbackError,
) => {
  if (response.status === 1) {
    callbackSuccess()
  } else {
    let message = 'Lỗi!'
    let errorCode = +response.error_code
    if (errorCode > 0) {
      ErrorConfig.forEach(err => {
        if (err[0] === errorCode) {
          message = err[1]
        }
      })
    }
    if (callbackError) {
      callbackError()
    }

    toast.error(message)
  }
}

export const defaultQueryClientOptions = {
  queries: {
    onError: queryErrorGetHandler,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  },
  // mutations: {
  //   onError: queryErrorHandler,
  //   onSuccess: mutationSuccessGetHandler,
  // },
}

export const queryClient = new QueryClient({
  defaultOptions: defaultQueryClientOptions,
})
