const ErrorConfig = [
  [1, 'Token không hợp lệ'],

  [2, 'Truy cập không hợp lệ'],
  [3, 'Id trống, dữ liệu không hợp lệ'],
  [4, 'Mã số trống, dữ liệu không hợp lệ'],
  [5, 'Mật khẩu trống, dữ liệu không hợp lệ'],
  [
    6,
    'Tài khoản thành viên đã bị khóa, vui lòng liên hệ admin để được hỗ trợ',
    0,
  ],
  [
    7,
    'Đăng nhập thất bại, mã số hoặc mật khẩu không đúng, vui lòng kiểm tra lại',
  ],
  [8, 'Thông tin tìm kiếm trống, vui lòng kiểm tra lại'],
  [9, 'Id level trống, dữ liệu không hợp lệ'],
  [10, 'Id trạng thái nhân viên trống, dữ liệu không hợp lệ'],
  [11, 'Id nhân viên trống, dữ liệu không hợp lệ'],
  [12, 'Id đại lý trống, dữ liệu không hợp lệ'],
  [13, 'Không tìm thấy đại lý này trong hệ thống, vui lòng kiểm tra lại'],
  [14, 'Tên đại lý trống, dữ liệu không hợp lệ'],
  [15, 'Ngày sinh trống, dữ liệu không hợp lệ '],
  [16, 'Email trống, dữ liệu không hợp lệ'],
  [17, 'Số CMND hoặc số thẻ căn cước trống, dữ liệu không hợp lệ'],
  [18, 'Ngày cấp CMND hoặc thẻ căn cước trống, dữ liệu không hợp lệ'],
  [19, 'Nơi cấp CMND hoặc thẻ căn cước trống, dữ liệu không hợp lệ'],
  [20, 'Địa chỉ thường trú trống, dữ liệu không hợp lệ'],
  [21, 'Địa chỉ tạm trú trống, dữ liệu không hợp lệ'],
  [22, 'Số zalo trống, dữ liệu không hợp lệ'],
  [23, 'Số điện thoại trống, dữ liệu không hợp lệ'],
  [24, 'Id ngân hàng trống, dữ liệu không hợp lệ'],
  [25, 'Tên ngân hàng trống, dữ liệu không hợp lệ'],
  [26, 'Chi nhánh ngân hàng trống, dữ liệu không hợp lệ'],
  [27, 'Link CMND mặt trước trống, dữ liệu không hợp lệ'],
  [28, 'Link CMND mặt sau trống, dữ liệu không hợp lệ'],
  [29, 'Id xã thường trú trống, dữ liệu không hợp lệ '],
  [30, 'Id xã tạm trú trống, dữ liệu không hợp lệ '],
  [31, 'Link avt trống, dữ liệu không hợp lệ'],
  [32, 'Id người tuyển dụng trống, dữ liệu không hợp lệ'],
  [33, 'Địa chỉ thường trú trống, dữ liệu không hợp lệ'],
  [34, 'Địa chỉ tạm trú trống, dữ liệu không hợp lệ'],
  [35, 'Địa chỉ email này đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [
    36,
    'Số CMND hoặc số thẻ căn cước này đã tồn tại trong hệ thống, vui lòng kiểm tra lại',
  ],
  [
    37,
    'Định dạng số CMND hoặc số thẻ căn cước không hợp lệ, định dạng hợp lệ là 9 hoặc 12 số, vui lòng kiểm tra lại',
  ],
  [38, 'Mã số thuế này đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [39, 'Số điện thoại này đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [
    40,
    'Số tài khoản ngân hàng này đã tồn tại trong hệ thống, vui lòng kiểm tra lại ',
  ],
  [
    41,
    'Ngày tháng năm sinh này chưa đủ điều kiện để đăng ký thành viên, Tuổi hợp lệ là từ 18 tuổi trở lên, vui lòng kiểm tra lại ',
  ],
  [
    42,
    'Link người tuyển dụng đã bị khóa, bạn không thể mở tài khoản dưới mã số này, xin vui lòng kiểm tra lại',
  ],
  [43, 'Quá trình khởi tạo tài khoản thất bại, xin vui lòng thử lại'],
  [44, 'Tên quốc gia trống, dữ liệu không hợp lệ'],
  [45, 'Quốc gia này đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [46, 'Không tìm thấy quốc gia này trong hệ thống, vui lòng kiểm tra lại'],
  [47, 'Tên tỉnh thành trống, dữ liệu không hợp lệ'],
  [48, 'Tỉnh thành này đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [49, 'Không tìm thấy tỉnh thành này trong hệ thống, vui lòng kiểm tra lại'],
  [50, 'Id quốc gia trống, dữ liệu không hợp lệ'],
  [51, 'Tên quận huyện trống, dữ liệu không hợp lệ'],
  [52, 'Quận huyện này đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [53, 'Không tìm thấy quận huyện này trong hệ thống, vui lòng kiểm tra lại'],
  [54, 'Id tỉnh thành trống, dữ liệu không hợp lệ'],
  [55, 'Tên phường xã trống, dữ liệu không hợp lệ'],
  [56, 'Phường xã này đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [57, 'Không tìm thấy phường xã này trong hệ thống, vui lòng kiểm tra lại'],
  [58, 'Id quận huyện trống, dữ liệu không hợp lệ'],
  [59, 'Tên ngân hàng trống, dữ liệu không hợp lệ'],
  [60, 'Ngân hàng này đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [61, 'Không tìm thấy ngân hàng này trong hệ thống, vui lòng kiểm tra lại'],
  [62, 'Mã ngân hàng trống, dữ liệu không hợp lệ'],
  [63, 'Mã ngân hàng này đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [
    64,
    'Đã có lỗi trong khi xóa dữ liệu. Có thể là dữ liệu đã được sử dụng tham chiếu hoặc lỗi do đường truyền internet. Vui lòng kiểm tra lại',
  ],
  [65, 'Tên khách hàng trống, dữ liệu không hợp lệ'],
  [66, 'Ngày sinh trống, dữ liệu không hợp lệ '],
  [67, 'Không tìm thấy khách hàng này trong hệ thống, vui lòng kiểm tra lại'],
  [68, 'Số CMND hoặc số thẻ căn cước trống, dữ liệu không hợp lệ'],
  [69, 'Ngày cấp CMND hoặc thẻ căn cước trống, dữ liệu không hợp lệ'],
  [70, 'Nơi cấp CMND hoặc thẻ căn cước trống, dữ liệu không hợp lệ'],
  [71, 'Địa chỉ thường trú trống, dữ liệu không hợp lệ'],
  [72, 'Địa chỉ tạm trú trống, dữ liệu không hợp lệ'],
  [73, 'Địa chỉ thường trú trống, dữ liệu không hợp lệ'],
  [74, 'Địa chỉ tạm trú trống, dữ liệu không hợp lệ'],
  [75, 'Số zalo trống, dữ liệu không hợp lệ'],
  [76, 'Số điện thoại trống, dữ liệu không hợp lệ'],
  [77, 'Id xã thường trú trống, dữ liệu không hợp lệ '],
  [78, 'Id xã tạm trú trống, dữ liệu không hợp lệ '],
  [
    79,
    'Số CMND hoặc số thẻ căn cước này đã tồn tại trong hệ thống, vui lòng kiểm tra lại',
  ],
  [
    80,
    'Định dạng số CMND hoặc số thẻ căn cước không hợp lệ, định dạng hợp lệ là 9 hoặc 12 số, vui lòng kiểm tra lại',
  ],
  [81, 'Từ ngày không đúng định dạng, dữ liệu không hợp lệ'],
  [82, 'Đến ngày không đúng định dạng, dữ liệu không hợp lệ'],
  [83, 'Số yêu cầu hợp đồng trống, dữ liệu không hợp lệ'],
  [84, 'Id tư vấn tài chính trống, dữ liệu không hợp lệ'],
  [85, 'Id tư vấn độc lập trống, dữ liệu không hợp lệ'],
  [86, 'Id văn phòng trống, dữ liệu không hợp lệ'],
  [87, 'Id khách hàng trống, dữ liệu không hợp lệ'],
  [88, 'Id nhà cung cấp trống, dữ liệu không hợp lệ'],
  [89, 'Id sản phẩm chính trống, dữ liệu không hợp lệ'],
  [90, 'Phí sản phẩm chính đang trống, dữ liệu không hợp lệ'],
  [91, 'Phí sản phẩm chính đang là số âm, dữ liệu không hợp lệ'],
  [92, 'Không tìm thấy hợp đồng này trong hệ thống, vui lòng kiểm tra lại'],
  [93, 'Id loại hợp đồng trống, dữ liệu không hợp lệ'],
  [94, 'Hợp đồng này đã được khóa lại bởi CS, vui lòng kiểm tra lại '],
  [95, 'Tên cấp bậc đại lý trống, dữ liệu không hợp lệ'],
  [96, 'Id sản phẩm chính trống, dữ liệu không hợp lệ'],
  [97, 'Mã số nhân viên đăng nhập trống, dữ liệu không hợp lệ'],
  [98, 'Mật khẩu nhân viên đăng nhập trống, dữ liệu không hợp lệ'],
  [99, 'Mã số nhân viên này đã bị khóa, vui lòng liên hệ admin để được hỗ trợ'],
  [
    100,
    'Đăng nhập thất bại, mã số hoặc mật khẩu không đúng, vui lòng kiểm tra lại',
  ],
  [101, 'Mã công ty trống, dữ liệu không hợp lệ'],
  [102, 'Tên công ty trống, dữ liệu không hợp lệ'],
  [103, 'Mã công ty đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [104, 'Không tìm thấy công ty này trong hệ thống, vui lòng kiểm tra lại'],
  [105, 'Id chi nhánh trống, dữ liệu không hợp lệ'],
  [106, 'Mã chi nhánh trống, dữ liệu không hợp lệ'],
  [107, 'Tên chi nhánh trống, dữ liệu không hợp lệ'],
  [108, 'Địa chỉ chi nhánh trống, dữ liệu không hợp lệ'],
  [109, 'Mã chi nhánh đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [110, 'Chi nhánh đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [111, 'Không tìm thấy chi nhánh này trong hệ thống, vui lòng kiểm tra lại'],
  [112, 'Id công ty trống, dữ liệu không hợp lệ'],
  [113, 'Id nhân viên trống, dữ liệu không hợp lệ'],
  [114, 'Tên nghề nghiệp trống, dữ liệu không hợp lệ'],
  [115, 'Nghề này đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [116, 'Không tìm thấy nghề này này trong hệ thống, vui lòng kiểm tra lại'],
  [117, 'Id phòng ban trống, dữ liệu không hợp lệ'],
  [118, 'Tên phòng ban trống, dữ liệu không hợp lệ'],
  [119, 'Phòng ban này đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [120, 'Không tìm thấy phòng ban này trong hệ thống, vui lòng kiểm tra lại'],
  [121, 'Tên tài liệu trống, dữ liệu không hợp lệ'],
  [122, 'Id loại tài liệu trống, dữ liệu không hợp lệ'],
  [123, 'Link tải tài liệu trống, dữ liệu không hợp lệ'],
  [124, 'Quyền thành viên xem tài liệu trống, dữ liệu không hợp lệ'],
  [125, 'Ẩn tài liệu trống, dữ liệu không hợp lệ'],
  [126, 'Tài liệu cấu hình hot trống, dữ liệu không hợp lệ'],
  [127, 'Tên tài liệu này đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [
    128,
    'Không tìm thấy tài liệu này này trong hệ thống, vui lòng kiểm tra lại',
  ],
  [129, 'Tên loại tài liệu trống, dữ liệu không hợp lệ'],
  [
    130,
    'Không tìm thấy tên loại tài liệu này trong hệ thống, vui lòng kiểm tra lại',
  ],
  [
    131,
    'Tên loại tài liệu này đã tồn tại trong hệ thống, vui lòng kiểm tra lại',
  ],
  [132, 'Id tư vấn tài chính trống, dữ liệu không hợp lệ'],
  [133, 'Mã tư vấn tài chính trống, dữ liệu không hợp lệ'],
  [134, 'Tên tư vấn tài chính trống, dữ liệu không hợp lệ'],
  [
    135,
    'Mã tư vấn tài chính này đã tồn tại trong hệ thống, vui lòng kiểm tra lại',
  ],
  [
    136,
    'Không tìm thấy tư vấn tài chính này trong hệ thống, vui lòng kiểm tra lại',
  ],
  [137, 'Id tư vấn độc lập trống, dữ liệu không hợp lệ'],
  [138, 'Mã tư vấn độc lập trống, dữ liệu không hợp lệ'],
  [139, 'Tên tư vấn độc lập trống, dữ liệu không hợp lệ'],
  [
    140,
    'Mã tư vấn độc lập này đã tồn tại trong hệ thống, vui lòng kiểm tra lại',
  ],
  [
    141,
    'Không tìm thấy tư vấn độc lập này trong hệ thống, vui lòng kiểm tra lại',
  ],
  [142, 'Id sản phẩm bổ sung trống, dữ liệu không hợp lệ'],
  [143, 'Id hợp đồng nhân thọ trống, dữ liệu không hợp lệ'],
  [144, 'Id chức vụ trống, dữ liệu không hợp lệ'],
  [145, 'Tên chức vụ trống, dữ liệu không hợp lệ'],
  [146, 'Chức vụ này đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [147, 'Không tìm thấy chức vụ này trong hệ thống, vui lòng kiểm tra lại'],
  [148, 'Hoa hồng nhà cung cấp trống, dữ liệu không hợp lệ'],
  [149, 'Hoa hồng nhà cung cấp âm, dữ liệu không hợp lệ'],
  [150, 'Hoa hồng đại lý trống, dữ liệu không hợp lệ'],
  [151, 'Hoa hồng đại lý âm, dữ liệu không hợp lệ'],
  [152, 'Hệ số PP trống, dữ liệu không hợp lệ'],
  [153, 'Hệ số PP âm, dữ liệu không hợp lệ'],
  [154, 'Hệ số PFYP trống, dữ liệu không hợp lệ'],
  [155, 'Hệ số PFYP âm, dữ liệu không hợp lệ'],
  [156, 'Xác thực sản phẩm nhân thọ trống, dữ liệu không hợp lệ'],
  [157, 'Id chi nhánh trống, dữ liệu không hợp lệ'],
  [158, 'Mã sản phẩm trống, dữ liệu không hợp lệ'],
  [159, 'Tên sản phẩm trống, dữ liệu không hợp lệ'],
  [160, 'Id đơn vị tính trống, dữ liệu không hợp lệ'],
  [161, 'Id loại sản phẩm trống, dữ liệu không hợp lệ'],
  [162, 'Id sản phẩm chính trống, dữ liệu không hợp lệ'],
  [163, 'Mã sản phẩm này đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [164, 'Không tìm thấy sản phẩm này trong hệ thống, vui lòng kiểm tra lại'],
  [165, 'Id nhà cung cấp trống, dữ liệu không hợp lệ'],
  [166, 'Mã nhà cung cấp này đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [
    167,
    'Không tìm thấy nhà cung cấp này trong hệ thống, vui lòng kiểm tra lại',
  ],
  [168, 'Mã nhà cung cấp trống, dữ liệu không hợp lệ'],
  [169, 'Tên nhà cung cấp trống, dữ liệu không hợp lệ'],
  [170, 'Id ngân hàng trống, dữ liệu không hợp lệ'],
  [171, 'Tên ngân hàng trống, dữ liệu không hợp lệ'],
  [172, 'Mail nhân viên này đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [173, 'CMND nhân viên này đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [
    174,
    'Số sổ bảo hiểm nhân viên này đã tồn tại trong hệ thống, vui lòng kiểm tra lại',
  ],
  [
    175,
    'Mã số thuế nhân viên này đã tồn tại trong hệ thống, vui lòng kiểm tra lại',
  ],
  [
    176,
    'Số điện thoại nhân viên này đã tồn tại trong hệ thống, vui lòng kiểm tra lại',
  ],
  [
    177,
    'Số thẻ ngân hàng nhân viên này đã tồn tại trong hệ thống, vui lòng kiểm tra lại',
  ],
  [178, 'Không tìm thấy nhân viên này trong hệ thống, vui lòng kiểm tra lại'],
  [179, 'Đây là tài khoản admin, không thể sửa '],
  [180, 'Đây là tài khoản admin, không thể xóa'],
  [181, 'Id nhóm quyền trống, dữ liệu không hợp lệ'],
  [182, 'Đây là tài khoản admin, không thể khóa'],
  [183, 'Đây là tài khoản admin, không thể reset'],
  [184, 'Id đơn vị tính trống, dữ liệu không hợp lệ'],
  [185, 'Tên đơn vị tính trống, dữ liệu không hợp lệ'],
  [186, 'Đơn vị tính này đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [187, 'Không tìm thấy đơn vị tính này trong hệ thống, vui lòng kiểm tra lại'],
  [188, 'Id cấp bậc trống, dữ liệu không hợp lệ'],
  [189, 'Không tìm thấy cấp bậc này trong hệ thống, vui lòng kiểm tra lại'],
  [190, 'Thông tin tìm kiếm đại lý trống, dữ liệu không hợp lệ'],
  [191, 'Id quyền trống, dữ liệu không hợp lệ'],
  [192, 'Tên quyền trống, dữ liệu không hợp lệ'],
  [193, 'Quyền này đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [194, 'Không tìm thấy quyền này trong hệ thống, vui lòng kiểm tra lại'],
  [195, 'Id form trống, dữ liệu không hợp lệ'],
  [196, 'Id button trống, dữ liệu không hợp lệ'],
  [197, 'Button này đã tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [198, 'Button này không tồn tại trong hệ thống, vui lòng kiểm tra lại'],
  [199, 'Id action button trống, dữ liệu không hợp lệ'],
  [200, 'Id trạng thái hợp đồng trống, vui lòng kiểm tra lại '],
  [201, 'Id hợp đồng nhân thọ không tồn lại, vui lòng kiểm tra lại '],
  [202, 'Hợp đồng này đã hoàn tất, không thể sửa, vui lòng kiểm tra lại'],
  [203, 'Hợp đồng dã được thụ lý, không thể xóa được'],
  [204, 'Cấp bậc sát hạch này đã tồn tại trong hệ thống'],
  [
    205,
    'Cấp bậc sát hạch này không tồn tại trong hệ thống, vui lòng kiểm tra lại ',
  ],
  [
    206,
    'Chương trình khuyến mãi không tồn tại trong hệ thống, vui lòng kiếm tra lại',
  ],
  [207, 'Chương trình khuyến mãi trống, vui lòng kiếm tra lại'],
  [208, 'Id sản phẩm trống, vui lòng kiểm tra lại '],
  [209, 'Tải hình không đúng định dạng, vui lòng kiểm tra lại'],
  [210, 'Dung lượng hình quá lớn so với quy định, vui lòng kiểm tra lại'],
  [212, 'Page size trống, vui lòng kiểm tra lại'],
  [213, 'Page num trống, vui lòng kiểm tra lại'],
  [214, 'Kết quả tìm kiếm không có dữ liệu, xui vui lòng thử lại'],
  [215, 'Mã otp trống, vui lòng kiểm tra lại'],
  [216, 'Mã otp này không tồn tại trong hệ thống, vui lòng kiểm tra lại '],
  [217, 'Mã otp này đã hết hạn, xin vui lòng lấy mã otp mới, xin cảm ơn'],
  [218, 'Mật khẩu cũ trống, vui lòng kiểm tra lại '],
  [219, 'Mật khẩu mới trống, vui lòng kiểm tra lại '],
  [220, 'Mật khẩu mới giống mật khẩu cũ, xin vui lòng kiểm tra lại'],
  [221, 'Mật khẩu không đúng, xin vui lòng kiểm tra lại '],
  [222, 'Số hợp đồng trống, vui lòng kiểm tra lại '],
  [223, 'Thông tin tìm kiếm trống, vui lòng kiểm tra lại '],
  [224, 'Số hợp đồng trống, vui lòng kiểm tra lại '],
  [
    225,
    'Số ngày cấu hình xem hợp đồng tái tục đang trống, vui lòng kiểm tra lại',
  ],
  [226, 'Số thứ tự trống, vui lòng kiểm tra lại'],
  [227, 'Mã đặt lịch này không tồn tại trong hệ thống, vui lòng kiểm tra lại '],
  [228, 'Không thể sửa đặt lịch tư vấn, vui lòng kiểm tra lại'],
  [229, 'Hợp đồng này đã có tư vấn tài chính nhận lịch, vui lòng kiểm tra lại'],
  [230, 'Hợp đồng này đã có tư vấn độc lập nhận lịch, vui lòng kiểm tra lại'],
  [
    231,
    'Hợp đồng này không tồn tại tư vấn tài chính yêu cầu trong hệ thống, vui lòng kiểm tra lại ',
  ],
  [
    232,
    'Hợp đồng này không tồn tại tư vấn tài chính yêu cầu trong hệ thống, vui lòng kiểm tra lại ',
  ],
  [233, 'Hợp đồng không đang ở trạng thái chờ nhận, vui lòng kiểm tra lại '],
  [
    234,
    'Hợp đồng này đang thiếu tư vấn tài chính hoặc tư vấn độc lập, vui lòng kiểm tra lại',
  ],
  [235, 'Id book status trống, vui lòng kiểm tra lại '],
  [
    236,
    'Mã số này chưa được phân quyền truy cập hệ thống, vui lòng liên hệ admin',
  ],
  [237, 'Mã số này chưa được cấp quyền truy cập, vui lòng liên hệ admin'],
  [238, 'Mã số này chưa được cấp quyền hành động, vui lòng liên hệ admin'],
  [239, 'Mail này không tồn tại, vui lòng kiểm tra lại'],
  [240, 'Mail id gửi trống, vui lòng kiểm tra lại '],
  [241, 'Mail id nhận trống, vui lòng kiểm tra lại '],
  [242, 'Tiêu đề mail trống, vui lòng kiểm tra lại'],
  [243, 'Nội dung mail trống, vui lòng kiểm tra lại'],
  [
    244,
    'Số hợp đồng đã tồn tại trong hệ thống, vui lòng kiểm tra lại hoặc liên hệ với bộ phân CS để được biết thêm chi tiết.',
  ],
  [
    245,
    'Hệ thống gửi mail đang có vấn đề, vui lòng cung cấp thông tin này với bộ phận IT để được hỗ trợ sớm nhất. Xin cảm ơn!',
  ],
  [
    246,
    'Hệ thống gửi sms đang có vấn đề, vui lòng cung cấp thông tin này với bộ phận IT để được hỗ trợ sớm nhất. Xin cảm ơn!',
  ],
  [
    247,
    'Thời gian giao kết không thể nhỏ hơn thời gian hiện tại, vui lòng kiểm tra lại',
  ],
  [
    248,
    'Đơn đặt lịch này đã hoàn tất, không thể hủy được, vui lòng kiểm tra lại',
  ],
  [249, 'Thưởng giới thiệu PNT trống, vui lòng kiểm tra lại '],
  [250, 'Thưởng giới thiệu NT trống, vui lòng kiểm tra lại '],
  [251, 'Thưởng bán hàng AA FA trống, vui lòng kiểm tra lại '],
  [252, 'Thưởng quản lý PUM trống, vui lòng kiểm tra lại '],
  [253, 'Thưởng quản lý UM trống, vui lòng kiểm tra lại '],
  [254, 'Thưởng quản lý BM trống, vui lòng kiểm tra lại '],
  [255, 'Thưởng quản lý BDM trống, vui lòng kiểm tra lại '],
  [256, 'Thưởng TVTC trống, vui lòng kiểm tra lại '],
  [257, 'Thưởng TVĐL trống, vui lòng kiểm tra lại '],
  [258, 'Thưởng văn phòng trống, vui lòng kiểm tra lại '],
  [259, 'Thưởng đồng chia BM trống, vui lòng kiểm tra lại '],
  [260, 'Thưởng đồng chia Academy trống, vui lòng kiểm tra lại '],
  [261, 'Quỹ phát triển kinh doanh trống, vui lòng kiểm tra lại '],
  [262, 'Quỹ đồng chia Diamond trống, vui lòng kiểm tra lại '],
  [263, 'Thưởng phát triển kinh doanh BDM1 trống, vui lòng kiểm tra lại'],
  [264, 'Thưởng phát triển kinh doanh BDM2 trống, vui lòng kiểm tra lại'],
  [265, 'Thưởng tái tục bán hàng K2 trống, vui lòng kiểm tra lại'],
  [266, 'Thưởng tái tục quản lý PUM K2 trống, vui lòng kiểm tra lại'],
  [267, 'Thưởng tái tục quản lý UM K2 trống, vui lòng kiểm tra lại'],
  [268, 'Thưởng tái tục bán hàng K3 trống, vui lòng kiểm tra lại'],
  [269, 'Thưởng bán hàng sản phẩm 1 lần đóng phí trống, vui lòng kiểm tra lại'],
  [270, 'FYP đạt FA trống, vui lòng kiểm tra lại'],
  [271, 'PP nhóm đạt PUM trống, vui lòng kiểm tra lại'],
  [272, 'PP nhóm đạt UM trống, vui lòng kiểm tra lại'],
  [273, 'PP nhóm đạt BM trống, vui lòng kiểm tra lại'],
  [274, 'PP nhóm đạt BDM trống, vui lòng kiểm tra lại'],
  [275, 'PP cá nhân đạt BM trống, vui lòng kiểm tra lại'],
  [276, 'PP cá nhân đạt BDM trống, vui lòng kiểm tra lại'],
  [277, 'PFYP tháng đạt BDM1 trống, vui lòng kiểm tra lại'],
  [278, 'PFYP tháng đạt BDM2 trống, vui lòng kiểm tra lại'],
  [279, 'Thưởng giới thiệu PNT không thể là số âm, vui lòng kiểm tra lại '],
  [280, 'Thưởng giới thiệu NT không thể là số âm, vui lòng kiểm tra lại '],
  [281, 'Thưởng bán hàng AA FA không thể là số âm, vui lòng kiểm tra lại '],
  [282, 'Thưởng quản lý PUM không thể là số âm, vui lòng kiểm tra lại '],
  [283, 'Thưởng quản lý UM không thể là số âm, vui lòng kiểm tra lại '],
  [284, 'Thưởng quản lý BM không thể là số âm, vui lòng kiểm tra lại '],
  [285, 'Thưởng quản lý BDM không thể là số âm, vui lòng kiểm tra lại '],
  [286, 'Thưởng TVTC không thể là số âm, vui lòng kiểm tra lại '],
  [287, 'Thưởng TVĐL không thể là số âm, vui lòng kiểm tra lại '],
  [288, 'Thưởng văn phòng không thể là số âm, vui lòng kiểm tra lại '],
  [289, 'Thưởng đồng chia BM không thể là số âm, vui lòng kiểm tra lại '],
  [290, 'Thưởng đồng chia Academy không thể là số âm, vui lòng kiểm tra lại '],
  [291, 'Quỹ phát triển kinh doanh không thể là số âm, vui lòng kiểm tra lại '],
  [292, 'Quỹ đồng chia Diamond không thể là số âm, vui lòng kiểm tra lại '],
  [
    293,
    'Thưởng phát triển kinh doanh BDM1 không thể là số âm, vui lòng kiểm tra lại',
  ],
  [
    294,
    'Thưởng phát triển kinh doanh BDM2 không thể là số âm, vui lòng kiểm tra lại',
  ],
  [295, 'Thưởng tái tục bán hàng K2 không thể là số âm, vui lòng kiểm tra lại'],
  [
    296,
    'Thưởng tái tục quản lý PUM K2 không thể là số âm, vui lòng kiểm tra lại',
  ],
  [
    297,
    'Thưởng tái tục quản lý UM K2 không thể là số âm, vui lòng kiểm tra lại',
  ],
  [298, 'Thưởng tái tục bán hàng K3 không thể là số âm, vui lòng kiểm tra lại'],
  [
    299,
    'Thưởng bán hàng sản phẩm 1 lần đóng phí không thể là số âm, vui lòng kiểm tra lại',
  ],
  [300, 'FYP đạt FA không thể là số âm, vui lòng kiểm tra lại'],
  [301, 'PP nhóm đạt PUM không thể là số âm, vui lòng kiểm tra lại'],
  [302, 'PP nhóm đạt UM không thể là số âm, vui lòng kiểm tra lại'],
  [303, 'PP nhóm đạt BM không thể là số âm, vui lòng kiểm tra lại'],
  [304, 'PP nhóm đạt BDM không thể là số âm, vui lòng kiểm tra lại'],
  [305, 'PP cá nhân đạt BM không thể là số âm, vui lòng kiểm tra lại'],
  [306, 'PP cá nhân đạt BDM không thể là số âm, vui lòng kiểm tra lại'],
  [307, 'PFYP tháng đạt BDM1 không thể là số âm, vui lòng kiểm tra lại'],
  [308, 'PFYP tháng đạt BDM2 không thể là số âm, vui lòng kiểm tra lại'],
  [309, 'Tài liệu này không đúng định dạng, vui lòng kiểm tra lại'],
  [310, 'Tài liệu tải lên vượt quá dung lượng cho phép, vui lòng kiểm tra lại'],
  [311, 'id lần đóng phí trống, vui lòng kiểm tra lại'],
  [312, 'PP K2 trống, vui lòng kiểm tra lại'],
  [313, 'PP K2 không thể là số âm, vui lòng kiểm tra lại'],
  [314, 'PP K3 trống, vui lòng kiểm tra lại'],
  [315, 'PP K3 không thể là số âm, vui lòng kiểm tra lại'],
  [316, 'Phí đóng 1 lần trống, vui lòng kiểm tra lại'],
  [317, 'Chi nhánh ngân hàng trống, vui lòng kiểm tra lại'],
  [318, 'Thưởng lãnh đạo 5% trống, vui lòng kiểm tra lại'],
  [319, 'Thưởng lãnh đạo 5% không thể là số âm, vui lòng kiểm tra lại'],
  [320, 'Hợp đồng này đã ở trạng thái đối soát'],
  [321, 'Hợp đồng này đã ở trạng thái chưa đối soát'],
  [
    322,
    'Chương trình đã kết thúc, không thể thực hiện thao tác này, xin vui lòng kiểm tra lại',
  ],
  [
    323,
    'Kỳ trả thưởng này không tồn tại trong hệ thống, vui lòng kiểm tra lại ',
  ],
  [324, 'Kỳ trả thưởng trống, vui lòng kiểm tra lại'],
  [325, 'Ngày ngân hàng chuyển trống, vui lòng kiểm tra lại'],
  [326, 'Ngày thanh toán trống, vui lòng kiểm tra lại'],
  [327, 'Doanh số PFYP quy đổi điểm đồng chia BM trống, vui lòng kiểm tra lại'],
  [328, 'Doanh số PFYP quy đổi điểm đồng chia BM số âm, vui lòng kiểm tra lại'],
  [329, 'Doanh số PFYP BDM2 số âm, vui lòng kiểm tra lại'],
  [330, 'Doanh số PFYP nhóm cá nhân số âm, vui lòng kiểm tra lại'],
  [331, 'Doanh số PFYP BDM2 trống, vui lòng kiểm tra lại'],
  [332, 'Doanh số PFYP nhóm cá nhân trống, vui lòng kiểm tra lại'],
  [333, 'Không thể xóa sản phẩm chính, vui lòng kiểm tra lại'],
  [334, 'Quyền lợi bảo hiểm trống, vui lòng kiểm tra lại'],
  [335, 'Thành viên này không phải là BDM up, vui lòng kiểm tra lại'],
  [336, 'Hợp đồng này chưa đủ điều kiện hủy, vui lòng kiểm tra lại'],
  [337, 'Hợp đồng này chưa hủy nên không thể phục hồi, vui lòng kiểm tra lại'],
  [338, 'Hợp đồng này không thể thu hồi, xin vui lòng kiểm tra lại'],
  [339, 'Thời gian chọn lớn hơn ngày hiện tại, xin vui lòng kiểm tra lại'],
  [340, 'Tên thông báo trống, vui lòng kiểm tra lại'],
  [341, 'Hình thông báo trống, vui lòng kiểm tra lại'],
  [342, 'Hide trống, vui lòng kiểm tra lại'],
  [343, 'Không tìm thấy thông báo này, vui lòng kiểm tra lại'],
  [344, 'Hot trống, vui lòng kiểm tra lại'],
  [345, 'Kỳ trả thưởng này đã bị khóa, vui lòng kiểm tra lại'],
  [346, 'DS Fyp trống'],
  [347, 'Phí đóng lệch, vui lòng kiểm tra lại'],
  [348, 'Mối quan hệ này không tìm thấy, vui lòng kiểm tra lại'],
  [349, 'Tên mối quạn hệ này trống, vui lòng kiểm tra lại'],
  [350, 'Id Um trống, vui lòng kiểm tra lại'],
  [351, 'Id Bm trống, vui lòng kiểm tra lại'],
  [352, 'Level mới trống, vui lòng kiểm tra lại'],
  [353, 'Bổ nhiệm cấp bậc không tìm thấy, vui lòng kiểm tra lại'],
  [354, 'Bổ nhiệm cấp bậc này không được sửa, vui lòng kiểm tra lại'],
  [355, 'Bổ nhiệm cấp bậc này không được xóa, vui lòng kiểm tra lại'],
  [356, 'Bổ nhiệm cấp bậc này không được duyệt, vui lòng kiểm tra lại'],
  [357, 'Ngừng hoạt động này không tìm thấy, vui lòng kiểm tra lại'],
  [358, 'Ngừng hoạt động này không được sửa, vui lòng kiểm tra lại'],
  [359, 'Ngừng hoạt động này không được xóa, vui lòng kiểm tra lại'],
  [360, 'Ngừng hoạt động này không được duyệt, vui lòng kiểm tra lại'],
  [361, 'Chuyển mã số này không tìm thấy, vui lòng kiểm tra lại'],
  [362, 'Chuyển mã số này không được sửa, vui lòng kiểm tra lại'],
  [363, 'Chuyển mã số này không được xóa, vui lòng kiểm tra lại'],
  [364, 'Nghỉ việc này không tìm thấy, vui lòng kiểm tra lại'],
  [365, 'Nghỉ việc này không được sửa, vui lòng kiểm tra lại'],
  [366, 'Nghỉ việc này không được xóa, vui lòng kiểm tra lại'],
  [367, 'Chuyển quản lý hợp đồng này không tìm thấy, vui lòng kiểm tra lại'],
  [368, 'Chuyển quản lý hợp đồng này không được sửa, vui lòng kiểm tra lại'],
  [369, 'Chuyển quản lý hợp đồng này không được xóa, vui lòng kiểm tra lại'],
  [370, 'Chuyển quản lý này không tìm thấy, vui lòng kiểm tra lại'],
  [371, 'Chuyển quản đồng này không được sửa, vui lòng kiểm tra lại'],
  [372, 'Chuyển quản đồng này không được xóa, vui lòng kiểm tra lại'],
  [373, 'Chuyển quản lý này không được duyệt, vui lòng kiểm tra lại'],
  [374, 'Chuyển nhánh này không tìm thấy, vui lòng kiểm tra lại'],
  [375, 'Chuyển nhánh đồng này không được sửa, vui lòng kiểm tra lại'],
  [376, 'Chuyển nhánh đồng này không được xóa, vui lòng kiểm tra lại'],
  [377, 'Chuyển nhánh này không được duyệt, vui lòng kiểm tra lại'],
  [378, 'Không thể chuyển nhánh vì thành viên này đã phát sinh doanh số'],
  [379, 'Không thể chuyển nhánh vì thành viên này đã có tuyến dưới'],
  [380, 'Trạng thái hợp đồng này không thể chuyển, vui lòng kiểm tra lại'],
  [
    381,
    'Tư vấn tài chính phải đạt tối thiểu cấp bậc FA, vui lòng kiểm tra lại',
  ],
  [382, 'Không thể xóa thành viên này vì đã phát sinh doanh số'],
  [383, 'Không thể xóa thành viên này vì đã phát sinh tuyến dưới'],
  [384, 'Tiền thưởng memo không thể nhỏ hơn 0'],
  [385, 'Khoản khấu trừ không thể lớn hơn 0'],
]

export default ErrorConfig
