// ****************** DEV ********************************
// export const PROXY = 'https://dev.bcaholdings.vn'
// export const PROXY_UPLOAD_IMAGE = 'https://bcaholdings.vn/upload/images'
// export const PROXY_UPLOAD_DOCUMENT = 'https://bcaholdings.vn/upload/documents'

// ****************** PRODUCT ********************************
export const PROXY = 'https://dev.b-alpha.vn:446'
export const PROXY_UPLOAD_IMAGE = 'https://portal.b-alpha.vn/upload/images'
export const PROXY_UPLOAD_DOCUMENT =
  'https://portal.b-alpha.vn/upload/documents'

export const PUBLIC_TOKEN = 'bca2022'
